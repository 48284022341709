import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UseTermsService } from '../../use-terms.service';
import { SharedService } from 'src/app/shared/shared.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-modal-policy-and-privacy',
  templateUrl: './modal-policy-and-privacy.component.html',
  styleUrls: ['./modal-policy-and-privacy.component.scss']
})
export class ModalPolicyAndPrivacyComponent implements OnInit, OnDestroy {

  onDestroyOpenModal$: any;
  onDestroyAuthObject$: any;
  onDestroyPrivacyTerms$: any;
  isVisible: boolean = false;
  isCheckedPolicyAndPrivacy: boolean = false;
  policyAndPrivacyText: string = '';
  isTermsMandatory: boolean = false;
  isPolicyMandatory: boolean = true;
  useTermsText: string = '';
  i18n: any = [];
  userAuth: any;
  buttonLeft: string = '';
  buttonRight: string = '';
  readOnly: boolean = false;
  userName: string = '';
  user: any;
  msgAcceptedDate: string = '';

  constructor(
    private accountsService: AccountsService,
    private useTermsService: UseTermsService,
    private sharedService: SharedService,
    public platformModalsService: PlatformModalsService,
    private localStorageService: LocalStorageService,
    private usersService: UsersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkTranslations();
    this.isUserLoggedIn();

    this.onDestroyOpenModal$ = this.accountsService.openModalPolicyAndPrivacy$.subscribe({
      next: (response) => {
        if (response.readOnly) {
          this.readOnly = true;
          this.isCheckedPolicyAndPrivacy = true;
          this.getDateAcepted();
        } else {
          this.readOnly = false;
        }

        if (response) {
          this.buttonLeft = this.i18n.policy_privacy_continue;
          this.buttonRight = this.i18n.policy_privacy_cancel;

          if (this.readOnly) {
            this.buttonRight = this.i18n.policy_privacy_close;
          }

        }

        if (response.openModal) {
          this.getUseTerms();
        }
      },
      error: () => {

      }
    })
    this.onDestroyAuthObject$ = this.accountsService.authObject$.subscribe({
      next: (response: any) => {
        this.userAuth = response;
      }
    })

  }

  checkTranslations() {
    let interval = setInterval(() => {
      if (localStorage.getItem('translations') !== null) {
        clearInterval(interval);
        this.getTranslations();
      }
    }, 200);
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Errors'), ...this.sharedService.getTranslationsOf('PolicyAndPrivacy') }
  }

  userLoggedIn: boolean;
  isUserLoggedIn() {
    this.sharedService.isLoggedIn.subscribe({
      next: (response) => {
        this.userLoggedIn = response;

      }
    })
  }

  getUseTerms() {
    if (this.userLoggedIn) {
      this.useTermsService.getUseTermsWithToken()
        .subscribe({
          next: (response) => {
            this.useTermsText = response.terms_use;
            this.isTermsMandatory = response.terms_mandatory;
            this.policyAndPrivacyText = response.terms_policy;
            //Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
            //Se não, o botão já fica habilitado de padrão
            if (!this.isTermsMandatory) {
              this.isCheckedPolicyAndPrivacy = true;
            }
            this.isVisible = true;
          },
          error: () => {

          }
        })
    } else {
      localStorage.removeItem('token');
      this.useTermsService.getUseTermsNoToken()
        .subscribe({
          next: (response) => {
            this.useTermsText = response.terms_use;
            this.isTermsMandatory = response.terms_mandatory;
            this.policyAndPrivacyText = response.terms_policy;
            //Se o termos de uso for mandatório, o usuário precisará clicar no botão Li e Concordo para habilitar o botão
            //Se não, o botão já fica habilitado de padrão

            this.isVisible = true;

          },
          error: () => {

          }
        })
    }
  }

  acceptPolicyAndPrivacy() {
    const options = {
      token: this.userAuth.token
    }
    this.useTermsService.acceptPolicyAndPrivacy(options)
      .subscribe({
        next: (response) => {
          this.acceptUseTerms();
          this.sharedService.loggedIn.next(true);
          this.sharedService.storeUserCredentials(this.userAuth);
          this.accountsService.setGoogleCredencials(this.userAuth);
          this.accountsService.isRedirectRoute();
          this.router.navigate(['']);
        },
        error: (err) => {
          this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
        }
      })

  }

  acceptUseTerms() {
    const options = {
      token: this.userAuth.token
    }
    this.useTermsService.acceptUseTerms(options)
      .subscribe({
        next: (response) => {
          this.sharedService.loggedIn.next(true);
          this.sharedService.storeUserCredentials(this.userAuth);
          this.accountsService.setGoogleCredencials(this.userAuth);
          this.accountsService.isRedirectRoute();
          this.router.navigate(['']);
        },
        error: (err) => {
          this.platformModalsService.toggle('message', this.i18n.error_system_error, 'close');
        }
      })

  }

  closeModalAndLoggin() {
    this.sharedService.loggedIn.next(true);
    this.sharedService.storeUserCredentials(this.userAuth);
    this.closeModal()
  }

  closeModalAndContinue() {
    this.closeModal()
  }

  receiveCheckButton(event: any) {
    this.isCheckedPolicyAndPrivacy = event;
  }

  closeModal() {
    if (!this.readOnly) {
      this.sharedService.deleteKeyLocalStorage('token');
      this.sharedService.deleteKeyLocalStorage('permissions');
    }

    this.isVisible = false;
    this.isCheckedPolicyAndPrivacy = false;
    this.policyAndPrivacyText = '';
  }

  ngOnDestroy(): void {
    if (this.onDestroyOpenModal$) this.onDestroyOpenModal$.unsubscribe();
    if (this.onDestroyAuthObject$) this.onDestroyAuthObject$.unsubscribe();
  }

  continueToUseTerms() {
    if (this.isTermsMandatory || this.readOnly) {
      this.accountsService.openModalUseTerms$.next({ openModal: true, readOnly: this.readOnly, terms_policy: this.useTermsText, terms_mandatory: this.isTermsMandatory });
      this.closeModal();
      return;
    }

    this.acceptPolicyAndPrivacy()
  }

  getDatesAccepted($externalId: any) {
    this.usersService.getUsersAcceptedTerms($externalId)
      .subscribe({
        next: (response) => {
          let currentLanguage;
          if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
            currentLanguage = localStorage.getItem('currentLanguage')
          }
          if (!currentLanguage) {
            currentLanguage = 'pt-BR'
          }

          if (response?.terms_policy_agreement_date) {
            let newDate = new Date(response?.terms_policy_agreement_date).toLocaleDateString(currentLanguage, { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" })
            this.msgAcceptedDate = `${this.i18n.use_terms_accept} ${newDate} ${this.i18n.use_terms_by} ${this.userName}`;
          }
        }
      })
  }

  getDateAcepted() {
    this.getUser();

    if (this.user.external_id) {
      this.getDatesAccepted(this.user.external_id);
    }
  }

  getUser() {
    this.sharedService
      .getUser()
      .subscribe((user) => {
        this.userName = user['name'];
        this.user = user;
      })
  }

}
