import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-box-login',
    templateUrl: './box-login.component.html',
    styleUrls: ['./box-login.component.scss']
})
export class BoxLoginComponent {
    @Input() background_login = '';
    @Input() image_side_login = '';
    @Input() hasBackButton = false;
    @Input() isLoading = false;
    @Input() i18n: any = {};
}
