import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxMaskModule } from 'ngx-mask';
import { CountdownComponentModule } from 'src/app/components/countdown/countdown.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { ThirdPartyCookieModule } from 'src/app/components/third-party-cookie/third-party-cookie.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { AccountsComponent } from './accounts.component';
import { AccountsGuard } from './accounts.guard';
import { AccountsRoutingModule } from './accounts-routing.module';
import { LoginGuardianComponent } from './components/login-guardian/login-guardian.component';
import { LoginGuardianCodeComponent } from './components/login-guardian-code/login-guardian-code.component';
import { LoginNormalComponent } from './components/login-normal/login-normal.component';
import { BoxLoginComponent } from './utils/box-login/box-login.component';
import { ContactSupportComponent } from './utils/contact-support/contact-support.component';
import { GoogleLoginComponent } from './utils/google-login/google-login.component';
import { MicrosoftLoginComponent } from './utils/microsoft-login/microsoft-login.component';
import { SSOLoginComponent } from './utils/sso-login/sso-login.component';
import { ModalResetPasswordComponent } from './utils/standalone-login/components/modal-reset-password/modal-reset-password.component';
import { ModalResetValidationCodeComponent } from './utils/standalone-login/components/modal-reset-validation-code/modal-reset-validation-code.component';
import { StandaloneLoginComponent } from './utils/standalone-login/standalone-login.component';
import { ModalPolicyAndPrivacyComponent } from './utils/use-terms/components/modal-policy-and-privacy/modal-policy-and-privacy.component';
import { ModalUseTermsComponent } from './utils/use-terms/components/modal-use-terms/modal-use-terms.component';
import { UseTermsComponent } from './utils/use-terms/use-terms.component';

@NgModule({
    declarations: [
        AccountsComponent,
        LoginGuardianComponent,
        BoxLoginComponent,
        ContactSupportComponent,
        LoginGuardianCodeComponent,
        LoginNormalComponent,
        GoogleLoginComponent,
        MicrosoftLoginComponent,
        SSOLoginComponent,
        StandaloneLoginComponent,
        ModalResetPasswordComponent,
        ModalResetValidationCodeComponent,
        UseTermsComponent,
        ModalUseTermsComponent,
        ModalPolicyAndPrivacyComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AccountsRoutingModule,
        NgxMaskModule.forRoot(),
        CountdownComponentModule,
        GenericModalModule,
        MessageModalModule,
        EditorModule,
        ThirdPartyCookieModule
    ],
    exports: [
        ModalUseTermsComponent,
        ModalPolicyAndPrivacyComponent,
    ],
    providers: [AccountsGuard]
})
export class AccountsModule {}
