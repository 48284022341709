<div class="flex items-center justify-between">
  <h2
    class="xs:hidden sm:hidden md:flex lg:flex text-lg font-bold text-customBlue-default leading-tight"
  >
    {{ i18n.users_list }}
  </h2>
  <div class="flex">
    <div>
      <form
        [formGroup]="formSearch" (keyup)="searchUser(formSearch)"
        class="flex items-center w-full rounded-lg border md:mb-0 md:w-full bg-customWhite-default border-customWhite-bWhite"
      >
        <label class="hidden" for="search-form">{{ i18n.users_search }}</label>
        <input
          class="p-2 pl-2 rounded-lg w-full outline-none"
          type="text"
          placeholder="{{ i18n.users_search }}"
          name="search_user"
          #search_user
          formControlName="search"
        />
        <button class="cursor-pointer p-2" type="submit">
          <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#8F9BB3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        </button>
      </form>
    </div>
    <div class="flex">
      <span class="xs:hidden sm:hidden lg:flex my-auto ml-6">{{
        i18n.users_show
      }}</span>
      <input
        type="number"
        class="ml-3 w-16 pl-2 rounded-lg border border-customWhite-bWhite font-semibold text-customGray-textGray h-8 my-auto"
        [min]="0"
        [value]="per_page"
        [(ngModel)]="per_page"
        (keyup)="showNumberUsers(per_page)"
        [ngClass]="{'border-customOrange-default': per_page < 2}"
      />
      <span class="xs:hidden sm:hidden lg:flex my-auto ml-3">{{
        i18n.users_results
      }}</span>
      <button
        *appCheckPermissions="'users'; type: 'create'"
        class="ml-3 py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default"
        (click)="openModal()"
      >
        <i class="plus-icon color-white-icon w-3 h-3"></i>
      </button>
    </div>
  </div>
</div>
<!-- Tabela de Usuários -->
<table class="w-full mt-4">
  <thead class="min-w-full bg-customBlue-light rounded-t-lg h-20">
    <tr class="text-md text-customGray-textGray">
      <th class="text-md text-left pl-6">{{ i18n.users_name }}</th>
      <th class="text-md text-left">{{ i18n.users_role }}</th>
      <th class="xs:hidden sm:hidden lg:table-cell text-md">
        {{ i18n.users_status }}
      </th>
      <th class="xs:hidden sm:hidden lg:table-cell text-md">
        {{ i18n.users_last_activity }}
      </th>
      <th>{{ i18n.users_edition }}</th>
    </tr>
  </thead>
  <tbody *ngFor="let user of users">
    <tr class="border-b-2 border-customGray-superLight fade-search">
      <td class="block pl-8">
        <div class="flex items-center">
          <div class="lg:w-12 flex items-center justify-center pr-3 md:pr-6">
            <div></div>
            <app-user-frame [img_src]="user.image ?? ''" [user_name]="user.name ?? ''">
            </app-user-frame>
            <!-- <div *ngIf="user.image == ''" id="name" class="bg-inicieColors-orange font-bold flex items-center justify-center uppercase">
                {{user.ini}}
            </div>
            <div *ngIf="user.image !== ''">
                <img [src]="user.image | safeUrl" alt="imagem do usuario" class="rounded-full w-10 h-10">
            </div> -->
          </div>

          <div class="text-left lg:text-sm xs:text-xs w-truncate-mobile">
            <h2 class="font-semibold capitalize truncate">{{ user.name }}</h2>
            <span class="text-customGray-textGray block truncate">{{ user.email }}</span>
          </div>
        </div>
      </td>
      <td class="lg:text-sm xs:text-xs">
        <div class="sm:flex">
          <ng-container *ngIf="(user.roles && user.roles.length > 0)">
            <div
              class="text-customWhite-default capitalize mr-2"
              *ngFor="let userRole of user.roles; let i = index"
            >
                <!-- Adjust for Desktop -->
                <div class="hidden md:block"  *ngIf="userRole.role_external_id !== null && i === 0">
                    <span
                        class="py-2 px-4 rounded-md"
                        [ngClass]="{
                            'bg-customBlue-normal':
                            userRole?.role_type !== 'admin',
                            'bg-customBlue-royal': userRole?.role_type === 'admin'
                        }"
                    >
                        {{ userRole.role_name }}
                    </span>
                </div>
                <div 
                    class="hidden md:block" 
                    *ngIf="userRole.role_external_id !== null && i === 1"
                >
                    <span class="py-2 px-4 bg-customBlue-normal rounded-md">
                        +{{ user.roles.length - 1 }}
                    </span>
                </div>

                <!-- Adjust for Mobile -->
                <div class="md:hidden flex justify-center" *ngIf="userRole.role_external_id !== null && i === 0">
                    <span class="p-1 px-1.5 truncate block md:py-2 md:px-4 bg-customBlue-normal rounded-md"
                    [ngClass]="{'w-16': userRole?.role_type === 'admin'}"
                    >{{ userRole.role_name }}</span
                    >
                </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(user.roles && user.roles.length === 0)">
            <div class="px-1 text-customWhite-default capitalize flex justify-center">
              <div>
                <span class="p-1 px-1.5 md:py-2 md:px-4 block md:inline bg-customBlue-normal rounded-md">{{
                  i18n.users_none
                }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </td>
      <td class="text-center xs:hidden sm:hidden lg:table-cell">
        <div class="flex items-center" *ngIf="user.status === 'ACTIVE'">
          <span class="w-2 h-2 rounded-full mr-2 bg-customGreen-default"></span>
          <span>{{ i18n.users_active }}</span>
        </div>
        <div class="flex items-center" *ngIf="user.status === 'INACTIVE'">
          <span
            class="w-2 h-2 rounded-full mr-2 bg-customGray-superDark"
          ></span>
          <span>{{ i18n.users_inactive }}</span>
        </div>
      </td>
      <td class="text-center xs:hidden sm:hidden lg:table-cell">
        <span *ngIf="user.last_activity && user.last_activity !== null">{{ sharedService.getLocaleDate(user.last_activity) }}</span>
        <span *ngIf="user.last_activity === null" class="text-14">{{ i18n.user_not_login }}</span>
      </td>
      <td class="text-center cursor-pointer">
        <button (click)="openEdit.toggle($event)">
          <i class="overflow-menu-vertical-icon icon color-blue-icon"></i>
        </button>
        <p-overlayPanel
          [styleClass]="'arrow-tip'"
          [style]="{
            'border-radius': '14px',
            width: '12em',
            border: '1px solid #EFF4FA',
            padding: 'unset'
          }"
          #openEdit
        >
          <div class="py-1" role="none">
            <div
              *appCheckPermissions="'users'; type: 'edit'"
              (click)="openEdit.hide(); openEditUserModal(user)"
              class="flex items-center cursor-pointer hover"
            >
              <i class="icon_edit_menu edit-user-icon"></i>
              <span
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                >{{ i18n.users_edit }}</span
              >
            </div>
            <div
              *appCheckPermissions="'users'; type: 'delete'"
              (click)="openEdit.hide(); removeUser(user.external_id)"
              class="flex items-center cursor-pointer hover"
            >
              <i class="icon_edit_menu trash-icon"></i>
              <span
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                >{{ i18n.users_exclude }}</span
              >
            </div>
            <ng-container
              *appCheckPermissions="
                'enrollments';
                type: 'list'
              "
            >
              <div
                *ngIf="user.isTeacherOrStudent"
                (click)="openEdit.hide(); openModalAddDiscipline(user)"
                class="flex items-center cursor-pointer hover"
              >
                <i class="icon_edit_menu users-vinculate-icon"></i>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  >{{ i18n.users_vinculate_disciplines }}</span
                >
              </div>              
            </ng-container>
            <div
                *appCheckPermissions="'commission'; type: 'edit'"
                (click)="openEdit.hide(); openModalCreateCommission(user)"
                class="flex items-center cursor-pointer hover">
                <i class="icon_edit_menu certificate-icon"></i>                
                <span
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  >{{ i18n.users_vinculate_commission}}</span
                >
            </div>
            <div
              *appCheckPermissions="'users'; type: 'edit'"
              (click)="
                openEdit.hide(); openModalCreateProfile(user, (editMode = true))
              "
              class="flex items-center cursor-pointer hover"
            >
              <i class="icon_edit_menu user-badge-icon"></i>
              <span
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                >{{ i18n.users_edit_profiles }}</span
              >
            </div>
            <div *appCheckPermissions="'users_activate'; type: 'edit'">
              <div
                *ngIf="user.status === 'INACTIVE'"
                (click)="openEdit.hide(); changeStatusUser(user)"
                class="flex items-center cursor-pointer hover"
              >
                <i class="icon_edit_menu user-status-icon"></i>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  >{{ i18n.users_activate_user }}</span
                >
              </div>
            </div>
            <div *appCheckPermissions="'users_activate'; type: 'edit'">
              <div
                *ngIf="user.status === 'ACTIVE'"
                (click)="openEdit.hide(); changeStatusUser(user)"
                class="flex items-center cursor-pointer hover"
              >
                <i class="icon_edit_menu user-status-icon"></i>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  >{{ i18n.users_deactivate_user }}</span
                >
              </div>
            </div>
            <ng-container *appCheckPermissions="'impersonate_impersonificate'; type: 'create'">
              <div
              (click)="openEdit.hide(); impersonate(user)"
              class="flex items-center cursor-pointer hover"
              *ngIf="
                userLoggedIn.active_role !== 'student' &&
                userLoggedIn.can_impersonate === 1 &&
                user.id !== userLoggedIn.sub &&
                user.status === 'ACTIVE' &&
                user.roles &&
                user.roles.length &&
                (user.roles[0]?.role_type !== 'admin' || user.roles.length > 1)"
                >
                <i class="icon_edit_menu user-cog-outline-icon"></i>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  >{{ i18n.users_impersonafication }}</span
                >
              </div>
            </ng-container>

            <button *ngIf="user.hasStudentProfile" type="button" class="flex items-center hover" (click)="toggleModalListGuardian(true, user.external_id); openEdit.hide();">
                <span class="iconify text-customBlue-default w-6 h-5" data-icon="teenyicons:users-outline"></span>
                <p class="text-14 text-gray-700 px-4 py-2">{{ i18n.communication_app_guardians }}</p>
            </button>
          </div>
        </p-overlayPanel>
      </td>
    </tr>
  </tbody>
</table>

<div class="py-8 flex justify-center" *ngIf="users.length">
  <app-pagination
  #pagination
  class="pagination"
  [params]="paginationParams"
  (navigation)="goToPage($event)"
  ></app-pagination>
</div>

<!-- Modal add user -->
<app-generic-modal [isVisible]="showModal" [maxWidth]="'33.75rem'" [width]="'33.75rem'">
    <form [formGroup]="userForm" class="py-4">
        <header class="pb-6 px-4 border-b border-customBlue-glass text-customBlue-default text-center relative">
            <p class="text-26 font-bold">{{ editMode ? i18n.users_edit_user : i18n.users_create_new_user }}</p>
            <p class="text-14 mx-auto">{{ i18n.users_fill_all_fields }}</p>

            <button type="button" class="text-customGray-default text-18 right-4 top-0 absolute" (click)="showModal = !showModal">
                <span class="iconify" data-icon="simple-line-icons:close"></span>
            </button>
        </header>

        <article class="py-4 px-6 text-customGray-dark flex flex-col gap-2.5 sm:gap-4">
            <p class="font-medium">{{ i18n.users_user_data }}</p>

            <fieldset class="flex flex-col gap-1">
                <label for="name">{{ i18n.users_username }}</label>

                <input type="text" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16" id="name" formControlName="name" required 
                />
                
                <!-- Validation -->
                <div *ngIf="userForm.controls['name'].invalid && (userForm.controls['name'].dirty || userForm.controls['name'].touched)">
                    <p class="text-customOrange-default italic text-14" *ngIf="userForm.controls['name'].errors?.['required']">
                        {{ i18n.users_name_is_required }}
                    </p>
                </div>
            </fieldset>

            <fieldset class="flex flex-col gap-1">
                <label for="email">{{ i18n.users_email }}</label>

                <input type="email" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16" id="email" formControlName="email" required (input)="convertInputToLowerCase('email')"
                />

                <!-- Validation -->
                <div *ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)">
                    <p class="text-customOrange-default italic text-14" *ngIf="userForm.controls['email'].errors?.['required']">
                        {{ i18n.users_email_is_required }}
                    </p>

                    <p class="text-customOrange-default italic text-14" *ngIf="userForm.controls['email'].errors?.['email']">
                        {{ i18n.users_email_is_invalid }}
                    </p>
                </div>
            </fieldset>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <fieldset class="flex flex-col gap-1" *ngIf="!editMode">
                    <label for="userCode">{{ i18n.users_code }}</label>
    
                    <input type="text" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16" id="userCode" formControlName="userCode" required (keyup)="removeSpecialCharacterOfUserCode($event)"
                    />
    
                    <!-- Validation -->
                    <div *ngIf="userForm.controls['userCode'].invalid && (userForm.controls['userCode'].dirty || userForm.controls['userCode'].touched)">
                        <p class="text-customOrange-default italic text-14" *ngIf="userForm.controls['userCode'].errors?.['required']">
                            {{ i18n.users_user_code_required }}
                        </p>
                    </div>
                </fieldset>

                <fieldset class="flex flex-col gap-1">
                    <label for="phone">{{ i18n.users_phone }}</label>
    
                    <input type="text" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16" id="phone" formControlName="phone" mask="(00) 0000-0000||(00) 00000-0000" (change)="checkOrgunit()"
                    />
                </fieldset>
            </div>

            <fieldset>
                 <ul class="flex flex-col gap-4">
                    <li *ngFor="let assiduity of arrayAssiduity; let i = index">
                        <user-assiduity [assiduity]="assiduity" [lastItem]="((arrayAssiduity.length === i+1) && arrayAssiduity.length < 2)" (action)="actionEvent($event, i)"></user-assiduity>
                    </li>
                </ul>
            </fieldset>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <fieldset class="flex flex-col gap-1">
                    <label for="language">{{ i18n.users_language }}</label>
    
                    <select name="lang" id="lang" formControlName="lang" class="w-full bg-transparent border border-customGray-mediumGray rounded-sm py-2 px-4 user__language--select">
                        <option [value]="lang" *ngFor="let lang of langsList">{{ translateLanguageCode(lang) }}</option>
                     </select>
                </fieldset>

                <fieldset class="flex flex-col gap-1">
                    <p>{{ i18n.users_avatar }}</p>

                    <div class="flex items-center gap-2">
                        <label *ngIf="imgUser === ''" (click)="openUploadModal()" class="flex flex-col justify-center items-center bg-customBlue-light text-blue rounded-full shadow-lg border border-customGray-superLight cursor-pointer h-9" [style.minWidth]="'2.25rem'">
                            <i class="upload-icon icon_edit_menu"></i>
                        </label>

                        <label *ngIf="imgUser !== ''" (click)="openUploadModal()" class="userImageDiv w-10 h-10 flex flex-col items-center justify-center bg-customBlue-light text-blue rounded-full shadow-lg border border-customGray-superLight cursor-pointer">
                            <div class="userImage">
                                <img [src]="imgUser | safeUrl" alt="imagem do usuario" (error)="onImageError()" class="rounded-full w-10 h-10"/>
                            </div>
                            
                            <div class="replaceImg ease-in duration-300">
                                <i class="upload-icon icon_edit_menu h-9" [style.minWidth]="'2.25rem'"></i>
                            </div>
                        </label>
                        
                        <div class="inline-grid">
                            <span *ngIf="imgUser === ''" class="text-12 text-customGray-textGray">{{ i18n.users_no_file_selected }}</span>
                            <small [style.fontSize]="'0.5625rem'">{{ i18n.users_max_size_file }}</small>
                        </div>
                    </div>
                </fieldset>
            </div>

            <fieldset class="flex items-center justify-between gap-2 mt-2">
                <input [checked]="user?.can_impersonate" (change)="onChangeCheckBox(!user?.can_impersonate, displayPanel)" id="toogle" [style.height]="'1.4rem'" class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer" type="checkbox"/>

                <button type="button" class="flex items-center justify-end gap-2">
                    <label class="text-right w-5/6 sm:w-auto">{{ i18n.users_activate_impersonafication }}</label>
                    
                    <div #alertIcon class="relative" (click)="displayPanel.toggle($event)">
                        <span class="iconify text-customGray-default text-22 " data-icon="material-symbols:help-outline">
                        </span>
                        <p-overlayPanel #displayPanel [styleClass]="'arrow-tip'" [style]="{'border-radius': '14px', width: '18em', padding: 'unset', 'background-color': '#FEF8E6'}">
                            <div>
                                <div class="flex justify-center">
                                    <i class="attention-icon attention mb-2"></i>
                                </div>
                                
                                <span [innerHtml]="i18n.users_activate_impersonafication_alert | safeHTML"></span>
                            </div>
                        </p-overlayPanel>
                    </div>
                </button>
            </fieldset>
        </article>

        <footer class="px-6 sm:py-4 flex justify-center xsm:items-center items-stretch gap-4 flex-col sm:flex-row">
            <button class="bg-customBlue-default text-customWhite-default rounded font-bold px-10 py-2 w-auto xsm:w-max" type="button" [ngClass]="{ 'opacity-75 cursor-not-allowed': userForm.invalid }" [disabled]="userForm.invalid" (click)="saveUser()">
                {{ editMode ? i18n.users_save : i18n.users_continue }}
            </button>
            <button class="text-customBlue-default font-bold px-10 py-2 w-auto xsm:w-max" type="button" (click)="showModal = !showModal">
                {{ i18n.users_cancel }}
            </button>
        </footer>
    </form>
</app-generic-modal>

<!-- Modal Create Profile -->
<div
  *ngIf="showModalCreateProfile"
  class="animate-fade-in-down overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex transition duration-1000 ease-in-out"
>
  <div
    class="mx-auto absolute bg-customWhite-default rounded-lg w-full"
    style="max-width: 32.3125rem;"
  >
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t"
      >
        <div class="text-center">
          <h3 class="text-2xl font-semibold text-customBlue-default">
            {{ i18n.users_profile_registration }}
          </h3>
          <p class="text-sm text-customBlue-default">
            {{ i18n.users_edit_users_profile }}
          </p>
        </div>
      </div>
      <i
        class="close-btn-icon"
        (click)="showModalCreateProfile = !showModalCreateProfile"
      >
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.716 6.58203C12.716 6.50039 12.6492 6.43359 12.5676 6.43359L11.343 6.43916L9.49863 8.63789L7.65615 6.44102L6.42969 6.43545C6.34805 6.43545 6.28125 6.50039 6.28125 6.58389C6.28125 6.61914 6.29424 6.65254 6.3165 6.68037L8.73047 9.55635L6.3165 12.4305C6.29408 12.4577 6.28164 12.4917 6.28125 12.527C6.28125 12.6086 6.34805 12.6754 6.42969 12.6754L7.65615 12.6698L9.49863 10.4711L11.3411 12.668L12.5657 12.6735C12.6474 12.6735 12.7142 12.6086 12.7142 12.5251C12.7142 12.4898 12.7012 12.4564 12.6789 12.4286L10.2687 9.55449L12.6826 6.67852C12.7049 6.65254 12.716 6.61729 12.716 6.58203Z"
            fill="#A9AEB3"
          />
          <path
            d="M9.5 1.20703C4.90957 1.20703 1.1875 4.9291 1.1875 9.51953C1.1875 14.11 4.90957 17.832 9.5 17.832C14.0904 17.832 17.8125 14.11 17.8125 9.51953C17.8125 4.9291 14.0904 1.20703 9.5 1.20703ZM9.5 16.4219C5.68887 16.4219 2.59766 13.3307 2.59766 9.51953C2.59766 5.7084 5.68887 2.61719 9.5 2.61719C13.3111 2.61719 16.4023 5.7084 16.4023 9.51953C16.4023 13.3307 13.3111 16.4219 9.5 16.4219Z"
            fill="#A9AEB3"
          />
        </svg>
      </i>
      <!--body-->
      <form [formGroup]="profileForm">
        <div class="w-full p-4 px-8">
          <h1
            class="text-lg font-semibold text-customGray-dark xs:w-full md:w-1/2"
          >
            {{ i18n.users_user_profile }}
          </h1>
          <div *ngIf="editMode">
            <div
              formArrayName="newEnrollments"
              *ngFor="let formGroup of newEnrollments.controls; let i = index"
            >
              <div [formGroupName]="i" class="py-2">
                <div
                  class="text-customGray-dark flex justify-between items-center mb-2"
                >
                  <h5>{{ i18n.users_profile }} {{ i + 1 }}</h5>
                  <button
                    class="py-1 px-2 mx-2 rounded-md text-customWhite-default"
                    (click)="
                      removePermissionEdit(
                        i,
                        newEnrollments.controls[i]['controls'].role_external_id
                          .value,
                        defineOrgunits[i]
                      )
                    "
                  >
                    <i class="trash-can-icon w-5 h-7 color-gray-icon"></i>
                  </button>
                </div>
                <div class="flex items-center xs:flex-col">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      <div class="text-customGray-dark font-bold text-sm">
                        {{ i18n.users_permission }}
                      </div>
                      <select
                        class="text-customBlue-dark border border-customGray-mediumGray p-2.5 text-md w-full cursor-not-allowed remove-arrow opacity-100"
                        [attr.disabled]="true"
                        required
                        formControlName="role_external_id"
                      >
                        <option
                          *ngFor="let role of roles"
                          [value]="role.external_id"
                          [selected]="
                            role.external_id ===
                            newEnrollments.controls[i]['controls']
                              .role_external_id.value
                          "
                        >
                          {{ role.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          !newEnrollments.controls[i]['controls']
                            .role_external_id?.pristine &&
                          newEnrollments.controls[i]['controls']
                            .role_external_id?.errors
                        "
                      >
                        <p
                          class="text-xs font-bold"
                          style="color: #e8532c"
                          *ngIf="
                            newEnrollments.controls[i]['controls']
                              .role_external_id?.errors?.required
                          "
                        >
                          {{ i18n.users_role_is_required }}
                        </p>
                      </div>
                    </div>
                    <div class="w-full ml-2">
                      <div class="text-customGray-dark font-bold text-sm">
                        {{ i18n.users_organizational_unit }}
                      </div>
                      <div class="relative">
                      <select
                        class="text-customBlue-default border border-customBlue-default p-2 rounded-md text-16 w-full cursor-not-allowed remove-arrow pl-10 truncate opacity-100"
                        [disabled]="true"
                      >
                        <option
                          *ngFor="let ou of orgUnitsApart"
                          [selected]="
                            ou.external_id ===
                            newEnrollments.controls[i]['controls']
                              .ou_external_id.value
                          "
                        >
                          {{ ou.label }}
                        </option>
                      </select>
                      <svg class="z-10 top-1/3 absolute ml-4" width="16" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.35 11.7H0.65C0.477609 11.7 0.312279 11.6315 0.190381 11.5096C0.0684819 11.3877 3.89157e-09 11.2224 3.89157e-09 11.05V6.16655C-1.01587e-05 6.07367 0.0198841 5.98187 0.0583425 5.89733C0.0968009 5.81279 0.15293 5.73747 0.22295 5.67645L2.6 3.6036V0.65C2.6 0.477609 2.66848 0.312279 2.79038 0.190381C2.91228 0.0684819 3.07761 0 3.25 0H12.35C12.5224 0 12.6877 0.0684819 12.8096 0.190381C12.9315 0.312279 13 0.477609 13 0.65V11.05C13 11.2224 12.9315 11.3877 12.8096 11.5096C12.6877 11.6315 12.5224 11.7 12.35 11.7ZM4.55 10.4H6.5V6.4623L3.9 4.1951L1.3 6.4623V10.4H3.25V7.8H4.55V10.4ZM7.8 10.4H11.7V1.3H3.9V2.68255C4.0521 2.68255 4.20485 2.73585 4.32705 2.8431L7.57705 5.67645C7.64707 5.73747 7.7032 5.81279 7.74166 5.89733C7.78012 5.98187 7.80001 6.07367 7.8 6.16655V10.4ZM9.1 5.2H10.4V6.5H9.1V5.2ZM9.1 7.8H10.4V9.1H9.1V7.8ZM9.1 2.6H10.4V3.9H9.1V2.6ZM6.5 2.6H7.8V3.9H6.5V2.6Z" fill="#233674"/>
                        </svg>
                      </div>
                      <!-- <p-treeSelect  (onNodeSelect)="nodeSelect($event, i,newEnrollments.controls[i]['controls'].role_external_id.value, defineOrgunits[i])" (onNodeUnselect)="nodeUnselect($event, i,newEnrollments.controls[i]['controls'].role_external_id.value, defineOrgunits[i])" [(ngModel)]="defineOrgunits[i]" [ngModelOptions]="{standalone: true}" [options]="orgUnits" [metaKeySelection]="false" selectionMode="single" placeholder="Unidade"></p-treeSelect>     -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            formArrayName="enrollments"
            *ngFor="
              let formGroup of formArrayEnrollments.controls;
              let i = index
            "
          >
            <div [formGroupName]="i" class="py-2">
              <div
                class="text-customGray-dark flex justify-between items-center mb-2"
              >
                <h5>
                  {{ i18n.users_profile }} {{ i + 1 + newEnrollments.length }}
                </h5>
                <button
                  class="py-1 px-2 mx-2 rounded-md text-customWhite-default"
                  (click)="
                    removePermission(
                      i,
                      formArrayEnrollments.controls[i]['controls']
                        .role_external_id.value,
                      defineOrgunits[i + formArrayEnrollments.length]
                    )
                  "
                >
                  <i class="trash-can-icon w-5 h-7 color-gray-icon"></i>
                </button>
              </div>
              <div class="">
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <div class="text-customGray-dark font-bold text-sm">
                      {{ i18n.users_permission }}
                    </div>
                    <select
                      class="text-customBlue-dark border border-customGray-mediumGray p-2.5 text-md w-full"
                      (change)="
                        addPermission(
                          i,
                          formArrayEnrollments.controls[i]['controls']
                            .role_external_id.value,
                          defineOrgunits[i + newEnrollments.length]
                        )
                      "
                      required
                      formControlName="role_external_id"
                    >
                      <option
                        *ngFor="let role of roles"
                        [disabled]="role.disabled"
                        [value]="role.external_id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        !formArrayEnrollments.controls[i]['controls']
                          .role_external_id?.pristine &&
                        formArrayEnrollments.controls[i]['controls']
                          .role_external_id?.errors
                      "
                    >
                      <p
                        class="text-xs font-bold"
                        style="color: #e8532c"
                        *ngIf="
                          formArrayEnrollments.controls[i]['controls']
                            .role_external_id?.errors?.required
                        "
                      >
                        Cargo é obrigatório.
                      </p>
                    </div>
                  </div>
                  <div class="ml-2 w-full">
                    <div class="text-customGray-dark font-bold text-sm">
                      {{ i18n.users_organizational_unit }}
                    </div>
                      <div class="flex items-center gap-2 border border-customBlue-default rounded-md relative px-0.5">
                        <svg class="z-10 absolute ml-4" width="16" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.35 11.7H0.65C0.477609 11.7 0.312279 11.6315 0.190381 11.5096C0.0684819 11.3877 3.89157e-09 11.2224 3.89157e-09 11.05V6.16655C-1.01587e-05 6.07367 0.0198841 5.98187 0.0583425 5.89733C0.0968009 5.81279 0.15293 5.73747 0.22295 5.67645L2.6 3.6036V0.65C2.6 0.477609 2.66848 0.312279 2.79038 0.190381C2.91228 0.0684819 3.07761 0 3.25 0H12.35C12.5224 0 12.6877 0.0684819 12.8096 0.190381C12.9315 0.312279 13 0.477609 13 0.65V11.05C13 11.2224 12.9315 11.3877 12.8096 11.5096C12.6877 11.6315 12.5224 11.7 12.35 11.7ZM4.55 10.4H6.5V6.4623L3.9 4.1951L1.3 6.4623V10.4H3.25V7.8H4.55V10.4ZM7.8 10.4H11.7V1.3H3.9V2.68255C4.0521 2.68255 4.20485 2.73585 4.32705 2.8431L7.57705 5.67645C7.64707 5.73747 7.7032 5.81279 7.74166 5.89733C7.78012 5.98187 7.80001 6.07367 7.8 6.16655V10.4ZM9.1 5.2H10.4V6.5H9.1V5.2ZM9.1 7.8H10.4V9.1H9.1V7.8ZM9.1 2.6H10.4V3.9H9.1V2.6ZM6.5 2.6H7.8V3.9H6.5V2.6Z" fill="#233674"/>
                          </svg>
                        <p-treeSelect
                        class="w-full"
                          appendTo="body"
                          (onNodeSelect)="
                            nodeSelect(
                              $event,
                              i,
                              formArrayEnrollments.controls[i]['controls']
                                .role_external_id.value,
                              defineOrgunits[i + newEnrollments.length]
                            )
                          "
                          (onNodeUnselect)="
                            nodeUnselect(
                              $event,
                              i,
                              newEnrollments.controls[i]['controls']
                                .role_external_id.value,
                              defineOrgunits[i]
                            )
                          "
                          [(ngModel)]="defineOrgunits[i + newEnrollments.length]"
                          [ngModelOptions]="{ standalone: true }"
                          [options]="orgUnits"
                          [metaKeySelection]="false"
                          selectionMode="single"
                        ></p-treeSelect>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="falsa"
                *ngIf="
                  validatingAddPermission[i + newEnrollments.controls.length]
                    ?.isInvalid === true
                "
              >
                <p class="text-xs font-bold" style="color: #e8532c">
                  {{ i18n.users_profile_already_created }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-2">
            <button
              class="py-2 px-2 mx-2 rounded-full shadow-lg add text-customWhite-default"
              (click)="addFormEnrollment()"
            >
              <i class="plus-icon color-white-icon w-3 h-3"></i>
            </button>
            <span class="text-customBlue-dark">{{ i18n.users_add_new_profile }}</span>
          </div>
        </div>
        <!--footer-->
        <div class="flex items-center justify-center pb-6">
          <div>
            <button
              class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              [ngClass]="{
                'disabled:opacity-20 cursor-not-allowed': !orgUnitCheck
              }"
              [disabled]="!orgUnitCheck"
              (click)="saveAddProfile()"
            >
              {{ i18n.users_continue }}
            </button>
            <button
              class="text-customBlue-default background-transparent font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              (click)="closeModalCreateProfile()"
            >
              {{ i18n.users_cancel }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  *ngIf="showModalCreateProfile"
  class="opacity-25 fixed inset-0 z-40"
  [ngClass]="{ 'z-50': modalUploadOverlay }"
  style="background-color: #000"
></div>

<app-upload
  *ngIf="modalOpen$ | async"
  [sharingStatus]="true"
  [maxFiles]="maxFiles"
  [files]="files"
  [multiSelect]="false"
  [fileType]="'photo'"
  (closeEvent)="closeUploadModal()"
  (filesEvent)="receiveFiles($event)"
></app-upload>

<p-confirmDialog
  #cd
  [style]="{ width: '25vw' }"
  [transitionOptions]="'100ms'"
  acceptLabel="Sim"
  rejectLabel="Não"
></p-confirmDialog>
<p-dialog
  header="{{ i18n.users_error }}"
  [(visible)]="displayBasic"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <p>{{ textError }}</p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBasic = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<app-decision-modal></app-decision-modal>
<app-modal-profile-impersonate
  [impersonateUser]="impersonateUser"
  [i18n]="this.i18n"
  (sendCloseModal)="closeModalProfileImpersonate()"
  (sendProfileToImpersonate)="receiveProfileToImpersonate($event)"
  [isVisible]="isModalProgileImpersonateOpen"
>
</app-modal-profile-impersonate>


<modal-commission-team
  [commissionUser]="commissionUser"  
  [i18n]="i18n"
  [refreshCommissionTeam]="refreshCommissionTeam"
  (sendCloseModal)="closeModalCommissionTeam()"
  (sendCommission)="receiveCommissionTeam($event)"
  [isVisible]="isModalCommissionTeamOpen"
>  
</modal-commission-team>

<app-modal-vinculate-discipline></app-modal-vinculate-discipline>
<app-loading-modal [isVisible]="isLoading"></app-loading-modal>

<app-modal-list-guardian [isVisibleModalListGuardian]="valueModalListGuardian" [sonOfGuardianExternalID]="valueSonOfGuardianExternalID" [guardians]="guardiansList" [isLoading]="isLoadingGuardian" [i18n]="i18n" (closeModalListGuardian)="toggleModalListGuardian($event)" (callAddGuardian)="toggleModalAddGuardian($event)" (updatedListGuardian)="searchUser(formSearch)" (sendCPF)="cpfGuardian = $event" (sendIsLoading)="isLoadingGuardian = $event"></app-modal-list-guardian>

<app-modal-add-guardian [isVisibleModalAddGuardian]="valueModalAddGuardian" [sonOfGuardianExternalID]="valueSonOfGuardianExternalID" [CPF]="cpfGuardian" [i18n]="i18n" (closeModalAddGuardian)="toggleModalAddGuardian($event)" (callListGuardian)="toggleModalListGuardian($event)" (updatedListGuardian)="searchUser(formSearch)" (sendCPF)="cpfGuardian = $event"></app-modal-add-guardian>