import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';

// import { multi, single } from '../../data';
import { DashboardService } from './dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    user: any;
    dashData: any = [];
    currentLanguage: any;
    i18n: any = [];

    constructor(
        private dashboardService: DashboardService,
        private sharedService: SharedService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getUserDashboard();
        this.getDataDashboard();
        this.getTranslations();
    }

    getTranslations(): void {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Dashboard'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('Calendar'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUpdatedTranslations(): void {
        this.sharedService.translationsUpdate$.subscribe((translations: any) => {
            this.i18n = translations?.Dashboard;
        });
    }

    getUserDashboard(): void {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;

            if (this.user && this.user?.role_type === 'guardian') {
                this.router.navigate(['communication-app/dashboard-communication-guardian']);
            }
        });
    }

    getDataDashboard() {
        this.dashboardService.loadingTrue();
        this.dashboardService.getDash()
        // No término da requisição chama finalize
            .pipe(
                finalize(() => {
                    this.dashboardService.loadingFalse();
                })
            )
            .subscribe({
                next: (data) => {
                    this.dashData = data;
                },
                error: () => {
                    this.dashData = [];
                }
            });
    }
}
